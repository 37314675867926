<template>
    <div class="page">
        <el-main>
            <el-container>
                <el-row>
                    <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                        <el-form :inline="true">
                            <el-form-item>
                                <el-select v-model="checkUfaceConfig" multiple filterable collapse-tags placeholder="请选择配置" style="width: 220px" @change="SearchPage()" size="medium">
                                    <el-option v-for="(item, i) in checkUfaceConfigData" :key="i" :label="item.ConfigName" :value="item.AppID"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-input v-model="keyword" placeholder="请输入关键字查询" class="input-with-select" style="width: 300px; margin-right: 10px" size="medium">
                                <template #prepend>
                                    <el-select v-model="checkInput" multiple collapse-tags placeholder="请选择" style="width: 120px">
                                        <el-option v-for="(item, i) in dataTableSearch" :key="i" :label="item.label" :value="item.attr"></el-option>
                                    </el-select>
                                </template>
                            </el-input>
                            <slot name="searchSlot"></slot>
                            <el-form-item>
                                <el-button type="primary" icon="el-icon-search" size="medium" @click="SearchPage()">查询</el-button>
                                <el-button type="primary" v-has="'Add'" icon="el-icon-plus" size="medium" @click="deviceAdd">新增设备</el-button>
                                <el-button type="danger" v-has="'FakeDelete'" icon="el-icon-delete" size="medium" @click="DeleteDevice()">删除</el-button>
                            </el-form-item>
                            <el-form-item>
                                <el-popover placement="bottom" :width="400" trigger="click">
                                    <template #reference>
                                        <el-button size="medium"><i class="el-icon-arrow-down el-icon-menu" /> 列筛选</el-button>
                                    </template>
                                    <el-checkbox-group v-model="dataTableSearchList">
                                        <div v-for="(item, i) in dataTableList" :key="i">
                                            <el-checkbox v-if="item.visible != false" :label="item" checked style="display: block; margin: 10px" @change="filterFunHandle('filter', item.attr, i)">
                                                {{ item.label }}</el-checkbox>
                                            <el-checkbox v-else :label="item" style="display: block; margin: 10px" @change="filterFunHandle('filter', item.attr, i)">
                                                {{ item.label }}</el-checkbox>
                                        </div>
                                    </el-checkbox-group>
                                    <el-button size="small" type="text" @click="filterFunHandle('allchecked')">全选</el-button>
                                    <el-button size="small" type="text" @click="filterFunHandle('cancel')">取消全选</el-button>
                                </el-popover>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                        <el-table size="small" border ref="multipleTable" :data="dataTable" style="width: 99%" max-height="700" :height="tableHeight" :fit="true" @selection-change="handleSelectionChange">
                            <el-table-column type="selection" fixed="left" header-align="center" align="center"></el-table-column>
                            <el-table-column label="操作" fixed="right" header-align="center" align="center" width="300">
                                <template #default="scope">
                                    <el-button size="mini" v-has="'Update'" @click="deviceEdit(scope.row.Id)" type="primary" plain>修改</el-button>
                                    <el-popconfirm confirmButtonText="确定" cancelButtonText="取消" icon="el-icon-info" iconColor="red" title="确定删除吗？" @confirm="deviceDelete(scope.row.Id)">
                                        <template #reference>
                                            <el-button type="danger" v-has="'FakeDelete'" size="mini" plain>删除</el-button>
                                        </template>
                                    </el-popconfirm>
                                    <el-popconfirm confirmButtonText="确定" cancelButtonText="取消" icon="el-icon-info" iconColor="red" title="确定将设备恢复出厂设置吗？" @confirm="deviceReset(scope.row.DeviceKey, scope.row.DeviceName)">
                                        <template #reference>
                                            <el-button type="danger" size="mini">重置</el-button>
                                        </template>
                                    </el-popconfirm>
                                    <el-popconfirm confirmButtonText="确定" cancelButtonText="取消" icon="el-icon-info" iconColor="red" title="确定重启设备吗？" @confirm="deviceRestart(scope.row.DeviceKey)">
                                        <template #reference>
                                            <el-button type="danger" size="mini">重启</el-button>
                                        </template>
                                    </el-popconfirm>
                                </template>
                            </el-table-column>
                            <el-table-column v-for="(col, index) in dataTableSearchList" :key="index" sortable header-align="center" align="center" :prop="col.attr" :label="col.label">
                                <template #default="scope">
                                    <el-image v-if="col.type == 'img'" :src="scope.row[col.attr]" :preview-src-list="[scope.row[col.attr]]" fit="fill" style="width: 40px; height: 40px"></el-image>
                                    <el-tag size="medium" v-else-if="col.type == 'enum'">
                                        {{ col.formatters(scope.row[col.attr]) }}
                                    </el-tag>
                                    <div v-else-if="col.type == 'custom'">
                                        <el-tag size="medium" type="success" v-if="scope.row[col.attr] == '1'">
                                            在线
                                        </el-tag>
                                        <el-tag size="medium" type="danger" v-else-if="scope.row[col.attr] == '2'">
                                            不在线
                                        </el-tag>
                                        <el-tag size="medium" type="warning" v-else>
                                            {{ scope.row[col.attr] }}
                                        </el-tag>
                                    </div>
                                    <label v-else>{{ scope.row[col.attr] }}</label>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                        <paging :currentPage="currentPage" :pagesize="pageSize" :total="total" @sizeChange="handleSizeChange" @currentChange="handleCurrentChange" :pagesizes="pageSizes" />
                    </el-col>
                </el-row>
            </el-container>
        </el-main>
        <el-dialog v-model="dialogVisible" :title="dialogTitle" width="30%" :before-close="handleClose" destroy-on-close>
            <saveUfaceDevice @operationSuccess="operationSuccess" :deviceId="deviceId" />
        </el-dialog>
    </div>
</template>
<script>
import saveUfaceDevice from "./saveUfaceDevice";
export default {
    name: "UfaceDevice",
    components: {
        saveUfaceDevice,
    },
    data() {
        return {
            deviceId: 0,
            dialogTitle: "",
            dialogVisible: false, //创建修改设备
            checkUfaceConfig: "",
            checkUfaceConfigData: [],
            total: 0, //数据总条数
            pageSize: 10, //当前显示数据数量
            pageSizes: [10, 20, 50, 100],
            currentPage: 1, //分页当前页
            keyword: "", //搜索输入关键字
            checkInput: "", //选择所需要查询的内容
            dataConfig: [
                {
                    label: "Id",
                    attr: "Id",
                    isInput: false,
                    isDisplay: false,
                },
                {
                    label: "配置名称",
                    attr: "ConfigName",
                },
                {
                    label: "设备名称",
                    attr: "DeviceName",
                    isSearch: true,
                },
                {
                    label: "设备序列号",
                    attr: "DeviceKey",
                    isSearch: true,
                },
                {
                    label: "识别方式",
                    attr: "RecType",
                    type: "enum",
                    formatters: function (val) {
                        var result;
                        switch (val) {
                            case 1:
                                result = "本地识别";
                                break;
                            case 2:
                                result = "云端识别";
                                break;
                            default:
                                result = "未知";
                                break;
                        }
                        return result;
                    },
                },
                {
                    label: "位置",
                    attr: "CheckType",
                    type: "enum",
                    formatters: function (val) {
                        var result;
                        switch (val) {
                            case 0:
                                result = "校门";
                                break;
                            case 1:
                                result = "宿舍";
                                break;
                            default:
                                result = "未知";
                                break;
                        }
                        return result;
                    },
                },
                {
                    label: "考勤状态",
                    attr: "CheckStatus",
                    type: "enum",
                    formatters: function (val) {
                        var result;
                        switch (val) {
                            case 0:
                                result = "进";
                                break;
                            case 1:
                                result = "出";
                                break;
                            case 2:
                                result = "已打卡";
                                break;
                            default:
                                result = "未知";
                                break;
                        }
                        return result;
                    },
                },
                {
                    label: "在线状态",
                    attr: "OnlineState",
                    type: "custom",
                },
                {
                    label: "版本号",
                    attr: "VersionNo",
                },
                {
                    label: "设备状态",
                    attr: "State",
                    type: "enum",
                    formatters: function (val) {
                        var result;
                        switch (val) {
                            case 1:
                                result = "未绑定";
                                break;
                            case 2:
                                result = "已绑定";
                                break;
                            case 3:
                                result = "已禁用";
                                break;
                            default:
                                result = "未知";
                                break;
                        }
                        return result;
                    },
                    visible: false,
                },
                {
                    label: "设备类型",
                    attr: "Type",
                    type: "enum",
                    formatters: function (val) {
                        var result;
                        switch (val) {
                            case 0:
                                result = "Uface系列(000000)";
                                break;
                            case 1:
                                result = "1代";
                                break;
                            case 2:
                                result = "2代";
                                break;
                            case 3:
                                result = "3代";
                                break;
                            case 6:
                                result = "C";
                                break;
                            case 10:
                                result = "2C";
                                break;
                            case 50:
                                result = "5C";
                                break;
                            case 51:
                                result = "5K";
                                break;
                            case 52:
                                result = "DV300平板";
                                break;
                            case 57:
                                result = "DV300模组";
                                break;
                            case 58:
                                result = "CV500模组";
                                break;
                            case 59:
                                result = "无感系列";
                                break;
                            case 60:
                                result = "潜艇6C";
                                break;
                            default:
                                result = "未知";
                                break;
                        }
                        return result;
                    },
                    visible: false,
                },
                {
                    label: "设备标签",
                    attr: "DeviceTag",
                    visible: false,
                },
                {
                    label: "备注",
                    attr: "RemarkInfo",
                    visible: false,
                },
                {
                    label: "创建时间",
                    attr: "CreatedTime",
                    visible: false,
                },
                {
                    label: "更新时间",
                    attr: "UpdatedTime",
                    visible: false,
                },
            ],
            dataTableSearch: [], //搜索信息
            dataTableList: [], //显示列信息
            dataTableSearchList: [], //列筛选信息
            dataTable: [], //显示数据
            multipleSelection: [], //选中数据
            tableHeight: 200, //table高度
        };
    },
    mounted() {
        const that = this;
        that.$nextTick(function () {
            // 仅在整个视图都被渲染之后才会运行的代码
            //设置table位置
            let heightStyle = window.innerHeight - 230;
            that.tableHeight = heightStyle;

            //窗口大小改变时，table 高度设置
            window.onresize = () => {
                return (() => {
                    let heightStyle = window.innerHeight - 230;
                    that.tableHeight = heightStyle;
                })();
            };
        });
        that.initCheckUfaceConfigDataList();
        that.SearchPage();
        that.dataTableSearch = that.dataConfig.filter(
            (it) => it.isSearch == true
        ); //可搜索字段
        that.dataTableList = that.dataConfig.filter(
            (it) => it.isDisplay != false
        ); //可显示列
    },
    methods: {
        //初始化配置选择数据
        initCheckUfaceConfigDataList() {
            var that = this;
            that.$API.UfaceConfig.GetUfaceConfigList.get().then((res) => {
                that.checkUfaceConfigData = res;
            });
        },
        //页面搜索查询
        SearchPage() {
            let that = this;
            let json = {
                searchValue: "",
                pageNo: that.currentPage,
                pageSize: that.pageSize,
                searchBeginTime: that.searchStartTime,
                searchEndTime: that.searchEndTime,
                sortField: "",
                sortOrder: "",
                descStr: "",
                AppIds: that.checkUfaceConfig,
            };
            if (that.checkInput.length > 0 && that.keyword != "") {
                var searchParameters = [],
                    input = that.keyword;
                for (let index = 0; index < that.checkInput.length; index++) {
                    const element = that.checkInput[index];
                    var searchParametersItem = {
                        field: element,
                        op: 8,
                        value: input,
                        orGroup: "",
                    };
                    searchParameters.push(searchParametersItem);
                }
                json.searchParameters = searchParameters;
            }

            that.$API.UfaceDevice.QueryByPage.post(json).then((res) => {
                if (res) {
                    that.total = res.TotalRows;
                    that.pagesize = res.PageSize;
                    that.currentPage = res.PageNo;
                    that.dataTable = res.Rows;
                }
            });
        },
        handleSizeChange(val) {
            this.pageSize = val;
            this.SearchPage();
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.SearchPage();
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        // 列筛选
        filterFunHandle(type, currentItem, index) {
            if (type === "allchecked") {
                // 全选
                this.dataTableSearchList = this.dataConfig.filter(
                    (it) => it.isDisplay != false
                );
            } else if (type === "cancel") {
                // 取消全选
                this.dataTableSearchList = []; // 复选框置为空，全部不选择
            }
        },
        deviceAdd() {
            this.deviceId = 0;
            this.dialogTitle = "新增设备";
            this.dialogVisible = true;
        },
        deviceEdit(id) {
            this.deviceId = id;
            this.dialogTitle = "修改设备";
            this.dialogVisible = true;
        },
        DeleteDevice() {
            var that = this;
            if (!that.multipleSelection || that.multipleSelection.length <= 0) {
                that.$message.warning("请选择需要删除的设备！");
                return;
            }
            var ids = new Array();
            var msgStr = "";
            that.multipleSelection.forEach((it) => {
                ids.push(it.Id);
                msgStr += `【${it.ConfigName}】`;
            });
            that.$confirm(
                `确认删除${msgStr}等${that.multipleSelection.length}条设备信息吗？`
            )
                .then(() => {
                    that.$API.UfaceDevice.FakeDelete.delete(ids).then((res) => {
                        if (res.Success) {
                            that.$message.success(res.Message);
                            that.SearchPage();
                        }
                    });
                })
                .catch(() => {});
        },
        deviceDelete(id) {
            var that = this;
            that.$API.UfaceDevice.FakeDelete.delete([id]).then((res) => {
                if (res.Success) {
                    that.$message.success(res.Message);
                    that.SearchPage();
                }
            });
        },
        deviceReset(deviceKey, deviceName) {
            var that = this;
            that.$confirm(
                `确定将设备【${deviceName}】恢复出厂设置吗？注：重置只删除设备内数据，重置后自动将云端数据同步恢复至设备。`,
                "警告",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            )
                .then(() => {
                    that.$API.UfaceDevice.UfaceDeviceReset.post(deviceKey).then(
                        (res) => {
                            if (res.Success) {
                                that.$message.success(res.Message);
                            } else {
                                that.$message.error(res.Message);
                            }
                            that.SearchPage();
                        }
                    );
                })
                .catch(() => {});
        },
        deviceRestart(deviceKey) {
            var that = this;
            that.$API.UfaceDevice.UfaceDeviceRestart.post(deviceKey).then(
                (res) => {
                    if (res.Success) {
                        that.$message.success(res.Message);
                    } else {
                        that.$message.error(res.Message);
                    }
                    that.SearchPage();
                }
            );
        },
        operationSuccess() {
            //操作成功后，关闭弹出框，刷新数据显示
            this.dialogVisible = false;
            this.deviceId = 0;
            this.SearchPage();
        },
        handleClose(done) {
            this.$confirm("确认关闭？")
                .then(() => {
                    done();
                })
                .catch(() => {});
        },
    },
};
</script>
<style scoped>
.page {
    margin: 5px;
    padding: 20px;
    min-height: 840px;
    width: 100%;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.el-pagination .btn-next .el-icon,
.el-pagination .btn-prev .el-icon {
    width: auto;
}
.drawerTitle {
    color: #409eff;
}
</style>
